import React, { useState, useRef, useEffect } from "react"
import { Input, Button, message } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"

import { useNavigate } from "react-router-dom"
import Util from "../../../services/util"
import { FUNCTION_CODE, ROLE_CODE } from "../../../store/constants"
import { useDispatch } from "react-redux"
import "./index.scss"
import { jwtGetInfo } from "../../../utils/jwtToken"
import { userLogin } from "../promises"
import { getOperAppsLists, getOrgs, changeOrg, logout } from "../../../api/service"
import { ACCESS_KEYS } from "../../../api/config/servicePort"
import { navConfig, deepLoopFloat } from "../../../components/TopNavbar/nav.config"

import Tenants from "./Tenants"

const MessageInput: React.FC = () => {
  const dispatch = useDispatch<any>()
  const [phone, setPhone] = useState<string>("")
  const [sms, setSms] = useState<string>("")
  const [messageApi, contextHolder] = message.useMessage()
  const [tr, setTr] = useState<number>(0)
  const timer: any = useRef(null)
  const util = Util.getInstance()
  const navigate = useNavigate()
  const [redirect, setRedirect] = useState<any>("")
  const [loginOrgsList, setLoginOrgsList] = useState<any>([])
  const [tenants, setTenants] = useState<{ tenantId: string; name: string }[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (tr === 60) timer.current = setInterval(() => setTr((tr) => --tr), 1000)
    else if (tr === 0) timer.current && clearInterval(timer.current)
  }, [tr])
  useEffect(() => {
    let redirect_url = new URLSearchParams(window.location.search.substring(1)).get("redirect")
    setRedirect(redirect_url)
    return () => {
      setRedirect("")
    }
  }, [])

  /**
   * 切换组织
   */
  const changeOrgHandle = async (item: { tenantId: string; name: string }) => {
    const res: any = await changeOrg({
      grant_type: "client_credentials",
      orgId: item.tenantId,
      // token: loginOrgsList.access_token_op,
    })
    const tokenMessage = {
      access_token_op: res.data.jwtToken,
    }
    console.log(res.data.jwtToken)
    localStorage.removeItem("operation_authorization")
    util.setSessiontoken(JSON.stringify(tokenMessage))
    let resultJwtToken = jwtGetInfo(res.data.jwtToken)
    console.log(resultJwtToken, "resultJwtToken")
    if (res.code === 200) {
      dispatch({
        type: ROLE_CODE,
        payload: {
          ...res.data.jwtToken,
        },
      })
      sessionStorage.setItem("tenantId", item.tenantId)
      sessionStorage.setItem("tenantName", item.name)
      window.localStorage?.setItem("operation_tissue", JSON.stringify(resultJwtToken))
      const result = await getOperAppsLists({})
      if (result.code == 200) {
        const resultData = result?.data?.map((ie) => {
          return ie.code
        })
        sessionStorage.setItem(ACCESS_KEYS, JSON.stringify(resultData))
        window.ACCESS_KEYS = resultData
        message.success("登录成功")
        console.log(redirect, "redirect")
        if (redirect) {
          window.location.href = redirect
        } else {
          let res: any = deepLoopFloat(navConfig, resultData)
          window.location.href = window.location.origin + res[0]?.children[0]?.path
        }
      } else {
        message.error(result.message || "获取权限列表失败")
      }
    }
  }
  const login = async () => {
    if (phone == "" || sms == "") {
      message.error("账号或密码不能为空")
      return
    }
    setLoading(true)
    let params = {
      username: phone,
      password: sms,
      mode: 0,
      appId: "1",
    }
    const data: any = await userLogin(params)
    if (data.code == 200) {
      const tokenMessage = {
        access_token_op: data.data.jwtToken,
      }
      util.setSessiontoken(JSON.stringify(tokenMessage))
      setLoginOrgsList(tokenMessage)
      getUserInfo()
      setLoading(false)
    } else {
      setLoading(false)
      // message.error(data.message || "登录失败")
    }
  }
  const getUserInfo = async () => {
    const res = await getOrgs()
    console.log(res, "res")
    if (res.code == 200) {
      if (res.data.length == 0) {
        message.error("无可用组织，请联系管理员！")
        return
      }
      const ltdList = res?.data?.map((item) => {
        return {
          tenantId: item.id,
          name: item.name,
        }
      })
      sessionStorage.setItem("ORGCHANGE", JSON.stringify(ltdList))
      if (ltdList.length === 1) {
        setTimeout(() => {
          changeOrgHandle(ltdList[0])
        }, 500)
      } else {
        setTenants(ltdList)
      }
    }
  }
  return (
    <div>
      <div className="sign-container-input-by-message">
        {contextHolder}
        <div className="sign-container-input-by-message-phone">
          <Input
            placeholder="请输入账号"
            prefix={<UserOutlined />}
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value)
            }}
          />
        </div>
        <div className="sign-container-input-by-message-sms">
          <Input.Password
            placeholder="密码"
            prefix={<LockOutlined />}
            value={sms}
            onChange={(e) => {
              setSms(e.target.value)
            }}
          />
        </div>
        <Button
          loading={loading}
          type="primary"
          onClick={login}
          className="sign-container-input-by-message-submit"
        >
          登录
        </Button>
      </div>
      <Tenants tenants={tenants} changeOrgHandle={changeOrgHandle} />
    </div>
  )
}
export default MessageInput
