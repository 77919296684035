import { useEffect } from "react"
import { useNavigate, useLocation, Outlet } from "react-router-dom"
import "./App.scss"
import "./page.scss"
import Util from "./services/util"
// import "./services/axios"
import "dayjs/locale/zh-cn"
import RoutesHome from "./routers/index"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorBoundaryFallback } from "./components/ErrorBoundaryFallback"
const App = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect((): void => {
    const util = Util.getInstance()
    util.overtimeLogOut()
    if (!util.isLogin()) {
      // navigate('/login');
    } else {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      navigate(location.pathname)
    }
  }, []) // eslint-disable-line

  return (
    // <ConfigProvider locale={locale}>
    <div className="App">
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={(error, info) => {
          console.log(error, info.componentStack)
        }}
      >
        <RoutesHome />
      </ErrorBoundary>
    </div>
    // </ConfigProvider>
  )
}

export default App
