import React, { memo } from "react"
import { Result } from "antd"

type props = { error: any; resetErrorBoundary: (...args: any[]) => void }
export type ErrorBoundaryFallbackProps = props
export const ErrorBoundaryFallback: React.FC<React.PropsWithChildren<ErrorBoundaryFallbackProps>> =
  memo((props) => {
    return (
      <Result
        style={{ background: "#fff", margin: 16, paddingTop: "20%", height: "100%", flex: 1 }}
        title="出错了"
        extra={props.error.message}
        status="error"
      />
    )
  })
ErrorBoundaryFallback.displayName = "错误边界"
