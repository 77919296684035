import React from "react"
import { Link } from "react-router-dom"
import nf from "../../assets/images/404.gif"
import "./index.scss"
const Error = () => {
  return (
    <div className="page-not-find">
      <div className="page-not-find-info">
        <p>抱歉您访问的地址不存在，请您检查是否有权限</p>
        <div className="page-not-find-info-url">
          <p>您也可以访问这些链接</p>
          <Link to="/login">登录页</Link>
        </div>
      </div>
      <img src={nf} alt="png" />
    </div>
  )
}
export default Error
