import React, { Suspense } from "react"
import { Spin } from "antd"

/**
 * @description 路由懒加载
 * @param {Element} Comp 需要访问的组件
 * @returns element
 */
const lazyLoad: any = (Comp: React.LazyExoticComponent<any>, CompProps): React.ReactNode => {
  return (
    <Suspense fallback={<Spin style={{ margin: "10% auto" }} size="large" />}>
      <Comp {...CompProps} />
    </Suspense>
  )
}

export default lazyLoad
